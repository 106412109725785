import {IndexCompetition} from "@/types/Competition";
import {httpService} from "@/util/HttpService";
import {CompetitionsOverview} from "@/modules/competition/service/CompetitionService";

class MyCalendarService {
    loadThisWeekCompetitions(
        filter: FilterContext
    ): Promise<Array<IndexCompetition>> {
        return httpService.post("/api/mobile/public/my-calendar/this-week", filter)
    }

    loadOther(
        filter: FilterContext
    ): Promise<CompetitionsOverview> {
        return httpService.post("/api/mobile/public/my-calendar/other-v2", filter)
    }

    loadAllLater(
        filter: FilterContext
    ): Promise<Array<IndexCompetition>> {
        return httpService.post(`/api/mobile/public/my-calendar/later/all-v2`, filter)
    }

    loadAllOlder(
        filter: FilterContext
    ): Promise<Array<IndexCompetition>> {
        return httpService.post(`/api/mobile/public/my-calendar/older/all-v2`, filter)
    }
}

export interface FilterContext {
    sportTypes: Array<string>,
    federations: Array<string>,
    championships: Array<FederationChampionshipFilterItem>,
    registrationOnly: boolean
}

export interface FederationChampionshipFilterItem {
    id: string,
    federation: string,
    groups: Array<FederationChampionshipGroupFilterItem>,
}

export interface FederationChampionshipGroupFilterItem {
    id: string,
    federation: string
}

export const myCalendarService = new MyCalendarService();