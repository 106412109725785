<template>
  <ion-app>
    <ion-menu side="start" menu-id="settings-menu" content-id="main-content" v-if="!isPlatform('desktop')">
      <SettingsMenu></SettingsMenu>
    </ion-menu>
    <ion-router-outlet id="main-content"/>
  </ion-app>
</template>

<script lang="ts" setup>
import {IonApp, IonMenu, IonRouterOutlet, isPlatform, modalController, toastController} from '@ionic/vue';
import SettingsMenu from "@/component/SettingsMenu.vue";
import "@/styles/utils.scss"
import {computed, onMounted, Ref, watch} from "vue";
import {localeStoreService} from "@/storage/StorageService";
import {useI18n} from "vue-i18n";
import {useKeycloak} from "@/authentication/vue-keycloak";
import {authenticationService} from "@/authentication/AuthenticationService";
import LoggedInUserCheckModal from "@/component/LoggedInUserCheckModal.vue";

const keycloak = useKeycloak()
const i18n = useI18n()
const {decodedToken}: { decodedToken: Ref } = keycloak as any;

const isLoggedIn = computed((): boolean => {
  return keycloak.isAuthenticated.value
})

watch(isLoggedIn, async () => {
  const email = keycloak.keycloak.tokenParsed?.preferred_username
  const response = await authenticationService.userCheck(email)

  if (response.needsPersonConfirmation) {
    const modal = await modalController.create({
      component: LoggedInUserCheckModal,
      componentProps: {
        data: response,
        email: decodedToken.value.given_name
      }
    })
    await modal.present()

    const modalResponse = await modal.onDidDismiss()

    if (modalResponse.role == 'submit') {
      const toast = await toastController.create({
        message: i18n.t("syncing.success"),
        color: 'success',
        position: 'top',
        buttons: [
          {
            text: 'ok',
            role: 'cancel'
          }
        ]
      })
      await toast.present()
    }
  }
})

onMounted(async () => {
  const lang = await localeStoreService.getLocale();
  if (lang) {
    i18n.locale.value = lang.toString()
  }
})

</script>

