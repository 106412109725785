import { createI18n } from 'vue-i18n';
import {EN_SCHEMA} from "@/locales/en";
import {NL_SCHEMA} from "@/locales/nl";

const messages = {
    EN_SCHEMA,
    NL_SCHEMA,
}

export interface Language {
    id: number,
    lang: string,
    lang_text: string,
    flag: string,
}

export const languages: Array<Language> = [
    {
        id: 1,
        lang_text: 'English',
        lang: "EN_SCHEMA",
        flag: "gb"
    },
    {
        id: 2,
        lang_text: 'Nederlands',
        lang: "NL_SCHEMA",
        flag: "nl"
    },
];

const i18n = createI18n({
    legacy: false,
    locale: 'NL_SCHEMA',
    fallbackLocale: 'NL_SCHEMA',
    globalInjection: true,
    messages: messages,
});

export default i18n;