import {defineStore} from "pinia";
import {myCalendarFilterStoreService} from "@/storage/StorageService";
import {searchCriteriaService} from "@/service/SearchCriteriaService";
import {
    FederationChampionshipFilterItem,
    FederationChampionshipGroupFilterItem,
    FilterContext
} from "@/modules/home/mycalendar/service/MyCalendarService";
import {UuidWithName} from "@/types/Global";

const MY_CALENDAR_FILTER_STORAGE_KEY = "MY_CALENDAR_FILTER_STORAGE_KEY"

export const useMyCalendarFilterStore = defineStore('myCalendarFilterStore', {
    state: () => {
        return {
            sportTypes: [] as Array<MyCalendarFilterItem>,
            federations: [] as Array<MyCalendarFilterItem>,
            championships: [] as Array<MyCalendarFederationChampionshipItem>,
            registrationOnly: false
        }
    },
    actions: {
        async saveFilter() {
            await this.save(MY_CALENDAR_FILTER_STORAGE_KEY, {
                federations: this.federations.filter(it => it.selected).map(it => it.id),
                championships: this.championships.filter(it => it.selected)
                    .map(it => {
                        return {
                            id: it.id,
                            federation: it.federation.uuid,
                            groups: it.groups.filter(it => it.selected).map(it => {
                                return {id: it.id, federation: it.federation.uuid}
                            })
                        }
                    }),
                sportTypes: this.sportTypes.filter(it => it.selected).map(it => it.id),
                registrationOnly: this.registrationOnly
            } as FilterStorageData)
        },
        async fetchFilter() {
            const data: FilterStorageData | null = await this.get(MY_CALENDAR_FILTER_STORAGE_KEY)

            if (data) {
                this.sportTypes = this.sportTypes.map(it => {
                    const isSelected = data.sportTypes.find(id => id == it.id) != null
                    return {
                        ...it,
                        selected: isSelected
                    }
                })
                this.federations = this.federations.map(it => {
                    const isSelected = data.federations.find(id => id == it.id) != null
                    return {
                        ...it,
                        selected: isSelected
                    }
                })
                this.championships = this.championships.map(it => {
                    const championship = data.championships.find(item => item.id == it.id && item.federation == it.federation.uuid)
                    return {
                        ...it,
                        selected: championship != null,
                        groups: it.groups.map(group => {
                            const isSelected = championship?.groups.find(item => item.id == group.id && item.federation == group.federation.uuid) != null
                            return {
                                ...group,
                                selected: isSelected
                            }
                        })
                    }
                })
                this.registrationOnly = data.registrationOnly
            }
        },
        async get(key: string): Promise<FilterStorageData | null> {
            const response = await myCalendarFilterStoreService.get(key)
            if (response) {
                return JSON.parse(response) as FilterStorageData
            } else return null
        },
        async save(key: string, context: FilterStorageData) {
            await myCalendarFilterStoreService.set(
                key,
                JSON.stringify(context)
            )
        },
        async loadCriteria() {
            const response = await searchCriteriaService.get()

            this.sportTypes = response.sportTypes.map(it => {
                return {
                    id: it,
                    name: it,
                    selected: false
                } as MyCalendarFilterItem
            })

            this.federations = []
            this.championships = []

            response.federations.forEach(fed => {
                const federation = fed.federation
                this.federations.push({
                    id: federation.uuid!,
                    name: federation.name,
                    selected: false
                })

                fed.championships.forEach(c => {
                    this.championships.push({
                        id: c.id,
                        name: c.name,
                        selected: false,
                        federation: federation,
                        groups: c.groups.map(g => {
                            return {
                                id: g.id,
                                name: g.name,
                                selected: false,
                                federation: federation
                            }
                        })
                    })
                })
            })
            await this.fetchFilter()
        },
        resetFederationFilters(federationId: string) {
            this.championships.filter(it => it.federation.uuid == federationId).forEach(it => {
                it.selected = false
                this.resetFederationChampionshipFilters(it.id)
            })
        },
        resetFederationChampionshipFilters(championshipId: string) {
            const championship = this.championships.find(it => it.id == championshipId)
            if (championship) {
                championship.groups.forEach(it => {
                    it.selected = false
                })
            }
        }
    },
    getters: {
        getFilterContext(): FilterContext {
            return {
                sportTypes: this.sportTypes
                    .filter((it: MyCalendarFilterItem) => it.selected)
                    .map((it: MyCalendarFilterItem) => it.id),
                federations: this.federations
                    .filter((it: MyCalendarFilterItem) => it.selected)
                    .map((it: MyCalendarFilterItem) => it.id),
                championships: this.championships
                    .filter((it: MyCalendarFederationChampionshipItem) => it.selected)
                    .map((it: MyCalendarFederationChampionshipItem) => {
                        return {
                            id: it.id,
                            federation: it.federation.uuid,
                            groups: it.groups.filter(it => it.selected).map(it => {
                                return {
                                    id: it.id,
                                    federation: it.federation.uuid,
                                } as FederationChampionshipGroupFilterItem
                            })
                        } as FederationChampionshipFilterItem
                    }),
                registrationOnly: this.registrationOnly
            } as FilterContext
        }
    }
})

export interface MyCalendarFederationFilterItem {
    id: string,
    name: string,
    selected: boolean,
    federation: UuidWithName
}

export interface MyCalendarFederationChampionshipItem {
    id: string,
    name: string,
    selected: boolean,
    federation: UuidWithName,
    groups: Array<MyCalendarFederationFilterItem>
}

export interface FilterStorageData {
    sportTypes: Array<string>,
    federations: Array<string>,
    championships: Array<FederationChampionshipFilterStorageItem>,
    registrationOnly: boolean
}

export interface FederationChampionshipFilterStorageItem {
    id: string,
    federation: string,
    groups: Array<FederationChampionshipGroupFilterStorageItem>
}

export interface FederationChampionshipGroupFilterStorageItem {
    id: string,
    federation: string
}

export interface MyCalendarFilterItem {
    id: string,
    name: string,
    selected: boolean
}