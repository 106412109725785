import {KeycloakAdapter, KeycloakInstance} from "@/authentication/keycloak-js/keycloak";

export function createIframeAdapter({
	kc,
	createPromise,
	parseCallback,
	processCallback,
}: {
	kc: KeycloakInstance,
	createPromise: () => any,
	parseCallback: (callbackUrl: string) => any,
	processCallback: (oauth: any, promise: any) => void,
}): KeycloakAdapter {
	return {
		login(options) {
			const promise = createPromise();
			const loginUrl = kc.createLoginUrl(options);

			showKeycloakInIframe({
				url: loginUrl,
				iframe: options?.iframeOptions?.iframe,
			})
				.then((callbackUrl) => {
					const oauth = parseCallback(callbackUrl);
					processCallback(oauth, promise);
				});

			return promise.promise;
		},
		logout(options) {
			const promise = createPromise();
			const logoutUrl = kc.createLogoutUrl(options);

			showKeycloakInIframe({ url: logoutUrl, hidden: true })
				.then(() => {
					promise.setSuccess(true);
				});

			return promise.promise;
		},
		register(options) {
			const promise = createPromise();
			const registerUrl = kc.createRegisterUrl(options);

			showKeycloakInIframe({
				url: registerUrl,
				iframe: options?.iframeOptions?.iframe,
			})
				.then((callbackUrl) => {
					const oauth = parseCallback(callbackUrl);
					processCallback(oauth, promise);
				});

			return promise.promise;
		},
		accountManagement() {
			const accountUrl = kc.createAccountUrl();
			if (typeof accountUrl !== 'undefined') {
				const promise = createPromise();

				showKeycloakInIframe({ url: accountUrl })

				return promise.promise;
			} else {
				throw "Not supported by the OIDC server";
			}
		},
		redirectUri(options) {
			if (options && options.redirectUri) {
				return options.redirectUri;
			} else if (kc.redirectUri) {
				return kc.redirectUri;
			} else {
				return "http://localhost";
			}
		}
	};
}

function showKeycloakInIframe({ url, iframe, hidden = false}: {
	url: string,
	iframe?: HTMLIFrameElement,
	hidden?: boolean,
}): Promise<string> {
	if (iframe) {
		return listenForMessageCallback(url, iframe);
	}

	const newIframe = document.createElement("iframe");
	newIframe.setAttribute("title", "Keycloak");
	if (hidden) {
		newIframe.style.display = "none";
	} else {
		newIframe.style.position = "absolute";
		newIframe.style.borderWidth = "0px";
		newIframe.style.width = "100%";
		newIframe.style.height = "100%";
	}
	document.body.appendChild(newIframe);

	return listenForMessageCallback(url, newIframe)
		.then((result) => {
			document.body.removeChild(newIframe);
			return result;
		});
}

function listenForMessageCallback(url: string, iframe: HTMLIFrameElement): Promise<string> {
	return new Promise((resolve) => {
		function messageCallback(event: MessageEvent) {
			if (!iframe || event.origin !== window.location.origin || iframe.contentWindow !== event.source) {
				return;
			}

			window.removeEventListener("message", messageCallback);

			resolve(event.data);
		}

		window.addEventListener("message", messageCallback);
		iframe.setAttribute("src", url);
	});
}
