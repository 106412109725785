import {createApp} from 'vue'
import './axios'
import App from './App.vue'
import AppStartFailed from './AppStartFailed.vue'
import router, {initializeUrlOpenListener} from '@/router';
import { SplashScreen } from '@capacitor/splash-screen';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "./styles/app.scss";

//vue keycloak
import {useKeycloak, vueKeycloak} from '@/authentication/vue-keycloak'

import i18n from './i18n';
import {createPinia} from "pinia";
import {provideWebstompPlugin} from "@/plugins/webstomp.plugin";

import {Config, loadDynamicConfig, loadStaticConfig} from "@/config";
import {keycloakOptions} from "@/authentication";

import {useCompetitionStore} from "@/store/CompetitionStore";
import {useMyCalendarFilterStore} from "@/modules/home/mycalendar/store/MyCalendarFilterStore";
import {useMyCalendarStore} from "@/modules/home/mycalendar/store/MyCalendarStore";
import {useChannelsStore} from "@/modules/home/channels/store/ChannelsStore";

loadStaticConfig()
    .then(loadDynamicConfig)
    .then(startApp)
    .catch(err => {
        console.error("could not loading dynamic config", err);
        createApp(AppStartFailed)
            .use(i18n)
            .use(IonicVue)
            .mount('#app')
    });

async function startApp(config: Config) {
    //await SplashScreen.hide()
    await SplashScreen.show({autoHide: false})

    const app = createApp(App)
        .use(vueKeycloak, keycloakOptions(config))
        .use(i18n)
        .use(createPinia())
        .use(IonicVue)
        .use(router);

    provideWebstompPlugin(
        app,
        {
            url: config.WEBSOCKET_URL,
            vhost: config.WEBSOCKET_VHOST,
            login: config.WEBSOCKET_USERNAME,
            passcode: config.WEBSOCKET_PASSWORD,
        },
    );

    await router.isReady();

    app.mount('#app');

    //load competitions data on app startup
    const competitionStore = useCompetitionStore()
    await competitionStore.loadLive()
    await competitionStore.loadThisWeek()
    await competitionStore.loadChannels()
    competitionStore.loadOther()

    //load my calendar data on app startup
    const myCalendarFilterStore = useMyCalendarFilterStore()
    const myCalendarStore = useMyCalendarStore()
    myCalendarFilterStore.loadCriteria()
        .then(() => {
            myCalendarStore.loadThisWeek(myCalendarFilterStore.getFilterContext)
            myCalendarStore.loadOther(myCalendarFilterStore.getFilterContext)
        })

    const channelsStore = useChannelsStore()
    channelsStore.load()

    await initializeUrlOpenListener();
    await SplashScreen.hide()
}
