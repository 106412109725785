import {defineStore} from "pinia";
import {
    SaveRegistrationShoppingCartItem,
    ShoppingCartItem,
    ShoppingCartItemError,
    ShoppingCartSummaryItem
} from "@/types/Cart";
import {cartService} from "@/modules/payment/service/CartService";

export const useCartStore = defineStore('cartStore', {
    state: () => {
        return {
            items: [] as Array<ShoppingCartItem>,
            summary: [] as Array<ShoppingCartSummaryItem>
        }
    },
    actions: {
        async get() {
            const response = await cartService.getCart()
            this.items = response.items
            this.summary = response.summary
        },
        async add(item: SaveRegistrationShoppingCartItem) {
            await cartService.addItem(item)
            this.get()
        },
        async delete(id: string) {
            const index = this.items.findIndex(it => it.id == id)

            if (index > -1) {
                this.items.splice(index, 1)
                await cartService.deleteItem(id)
                this.get()
            }
        },
        resetErrors() {
            this.items.forEach(item => {
                item.error = undefined
            })
        },
        addErrors(errors: Array<ShoppingCartItemError>) {
            this.items.forEach(item => {
                const error = errors.find(it => it.itemId == item.id)
                if (error) item.error = error
            })
        }
    }
})