<template>
  <ion-app>
    <ConfigView v-if="showConfig"></ConfigView>
    <template v-if="!showConfig">
      <EqifyHeader
          @touchstart="openConfigHoldStart" @touchend="openConfigHoldEnd"
          @mousedown="openConfigHoldStart" @mouseup="openConfigHoldEnd"
          @dragenter="openConfigHoldStart" @dragleave="openConfigHoldEnd"/>
      <ion-content class="ion-padding">
        <ion-text color="danger">
          <h6>
            {{ $t("start.failed.message") }}
          </h6>
          <ion-button @click="reload()">
            <ion-icon slot="start" :icon="refreshOutline"></ion-icon>
            {{ $t("start.failed.try_again") }}
          </ion-button>
        </ion-text>
      </ion-content>
      <ion-footer>
        <offline-item></offline-item>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="reload()">
              <ion-icon slot="start" :icon="refreshOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </template>
  </ion-app>
</template>

<script lang="ts" setup>
import { IonApp, IonContent, IonButton, IonIcon, IonToolbar, IonButtons, IonText, IonFooter } from '@ionic/vue';
import {refreshOutline} from 'ionicons/icons';
import "@/styles/utils.scss"
import {onMounted, onUnmounted, ref, watch} from "vue";
import {localeStoreService} from "@/storage/StorageService";
import {useI18n} from "vue-i18n";
import {languages} from "@/i18n";
import EqifyHeader from '@/component/EqifyHeader.vue';
import {App} from "@capacitor/app";
import {PluginListenerHandle} from "@capacitor/core";
import ConfigView from "@/modules/config/ConfigView.vue";
import OfflineItem from "@/component/OfflineItem.vue";
import {online} from "@/http/connection-status";

const i18n = useI18n()

let listener: PluginListenerHandle;

onMounted(async () => {
  const lang =  await localeStoreService.getLocale() || languages[0].lang;
  i18n.locale.value = lang.toString()

  listener = App.addListener('appStateChange', async state => {
   if (state.isActive) {
     reload();
   }
  });

  watch(online, (isOnline) => {
    if (isOnline) {
      reload();
    }
  });
});

onUnmounted(() => {
  if (listener) {
    listener.remove();
  }
});

function reload() {
  window.location.reload();
}

let holdStart: number;
let showConfig = ref(false);

function openConfigHoldStart() {
  holdStart = Date.now();
}

function openConfigHoldEnd() {
  const elapsed = Date.now() - holdStart;
  if (elapsed > 3000) {
    showConfig.value = true;
  }
}

</script>

