import {httpService} from "@/util/HttpService";
import {UuidWithName} from "@/types/Global";

class SearchCriteriaService {
    get(): Promise<SearchCriteria> {
        return httpService.get(`/api/mobile/public/search-criteria`)
    }
}

export interface SearchCriteria {
    sportTypes: Array<string>,
    federations: Array<FederationSearchCriteriaData>
}

export interface FederationSearchCriteriaData {
    federation: UuidWithName,
    championships: Array<SearchCriteriaChampionshipItem>
}

export interface SearchCriteriaItem {
    id: string
    name: string
}

export interface SearchCriteriaChampionshipItem {
    id: string,
    name: string,
    groups: Array<SearchCriteriaItem>
}

export const searchCriteriaService = new SearchCriteriaService();