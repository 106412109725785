import {createMemoryHistory, createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import waitForIsAuthenticated from '@/authentication/wait-for-is-authenticated';
import TabsContent from '@/component/TabsContent.vue'
import {App} from "@capacitor/app";
import {getPlatforms, isPlatform} from "@ionic/vue";
import {PLATFORMS} from "cordova-res/dist/platform";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/competition'
    },
    {
        path: '/tabs/',
        component: TabsContent,
        children: [
            {
                path: '',
                redirect: '/tabs/competition',
            },

            {
                path: 'today-masterlist',
                component: () => import("@/modules/competition/component/MasterlistWidgetDetails.vue")
            },
            {
                path: 'competition',
                component: () => import("@/modules/home/HomeView.vue"),
            },
            {
              path: 'competition/channels/:channelId',
                component: () => import("@/modules/home/channels/ChannelView.vue"),
            },
            {
                path: 'competition/later',
                component: () => import("@/modules/competition/view/AllLaterCompetitionsView.vue")
            },
            {
                path: 'competition/older',
                component: () => import("@/modules/competition/view/AllOlderCompetitionsView.vue")
            },

            {
                path: 'competition/my-calendar/later',
                component: () => import("@/modules/home/mycalendar/AllLaterMyCalendarCompetitionsView.vue")
            },
            {
                path: 'competition/my-calendar/older',
                component: () => import("@/modules/home/mycalendar/AllOlderMyCalendarCompetitionsView.vue")
            },

            {
                path: 'competition/:ref/information',
                component: () => import("@/modules/competition/view/CompetitionInformationView.vue")
            },

            //practitioners
            {
                path: 'competition/:ref/activities',
                component: () => import("@/modules/competition/view/CompetitionOverview.vue")
            },

            //register
            {
                path: 'competition/:ref/test/:testRef/register/individual',
                name: 'individual-register-view',
                component: () => import("@/modules/competition/component/registration/individual/IndividualRegistrationView.vue"),
                props: true
            },
            {
                path: 'competition/:ref/test/:testRef/register/horse-only',
                name: 'horse-only-register-view',
                component: () => import("@/modules/competition/component/registration/horseonly/HorseOnlyRegistrationView.vue"),
                props: true
            },

            //startlist
            {
                path: 'competition/:ref/activity/:activityRef/startlist',
                name: 'startlist-view',
                component: () => import("@/modules/competition/component/startlist/StartlistView.vue")
            },

            //arena
            {
                path: 'competition/:ref/arena/:arenaCode/day/:day',
                name: 'arena-view',
                component: () => import("@/modules/competition/component/arena/ArenaContainer.vue")
            },

            //practitioner detail
            {
                path: 'competition/:ref/activity/:activityRef/participant/:participantRef',
                component: () => import("@/modules/competition/component/startlist/StartlistEntryView.vue")
            },

            {
                path: 'competition/:ref/championship-ranking/:activityRef',
                name: 'championship-ranking-view',
                component: () => import("@/modules/competition/component/ranking/ChampionshipRankingView.vue")
            },

            //practitioner detail
            {
                path: 'competition/:ref/championship-ranking/:activityRef/practitioner/:practitionerRef',
                component: () => import("@/modules/competition/component/ranking/detail/ChampionshipRankingDetailView.vue")
            },

            {
                path: 'competition/:ref/competition-ranking/:activityRef',
                name: 'competition-ranking-view',
                component: () => import("@/modules/competition/component/ranking/competition/CompetitionRankingView.vue")
            },

            {
                path: 'competition/:ref/competition-ranking/:activityRef/practitioner/:practitionerRef',
                component: () => import("@/modules/competition/component/ranking/competition/CompetitionRankingEntryView.vue")
            },

            //search
            {
                path: 'search',
                component: () => import("@/modules/search/view/SearchView.vue")
            },
            {
                path: 'search/competitions/:query',
                component: () => import("@/modules/search/view/CompetitionsSearchResultsView.vue")
            },
            {
                path: 'search/combinations/:query',
                component: () => import("@/modules/search/view/CombinationsSearchResultsView.vue")
            },

            {
                path: 'championship',
                component: () => import("@/modules/championship/view/ChampionshipsView.vue")
            },
            {
                path: 'championship/:championshipId',
                component: () => import("@/modules/championship/view/ChampionshipView.vue")
            },
            {
                path: 'championship/:championshipId/sport-category/:sportCategoryId',
                component: () => import("@/modules/championship/view/ChampionshipTopRankingView.vue")
            },
            {
                path: 'championship/:championshipId/ranking',
                component: () => import("@/modules/championship/view/ChampionshipRankingView.vue")
            },

            //checkout
            {
                path: 'cart',
                component: () => import("@/modules/payment/CartView.vue")
            },

            //profile
            {
                path: 'profile',
                component: () => import("@/modules/profile/ProfileOverview.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/settings',
                component: () => import("@/modules/profile/view/ProfileSettingsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/settings/update-password',
                component: () => import("@/modules/auth/UpdatePasswordView.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/profile/settings/delete-account',
                component: () => import("@/modules/auth/DeleteAccountView.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/profile/registrations',
                component: () => import("@/modules/profile/view/registration/ProfileRegistrationCompetitionsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/orders',
                component: () => import("@/modules/profile/view/order/ProfileOrdersView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/order/:orderRef',
                component: () => import("@/modules/profile/view/order/ProfileOrderDetailView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/bills',
                component: () => import("@/modules/profile/view/bill/ProfileBillsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/bill/:billRef',
                component: () => import("@/modules/profile/view/bill/ProfileBillDetailView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/registrations/:competitionId',
                component: () => import("@/modules/profile/view/registration/ProfileCompetitionRegistrationsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/masterlists',
                component: () => import("@/modules/profile/view/masterlist/ProfileMasterlistCompetitionsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/masterlists/:competitionId',
                component: () => import("@/modules/profile/view/masterlist/ProfileCompetitionMasterlistView.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/profile/careers',
                component: () => import("@/modules/profile/view/careers/ProfileCareersView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/careers/:careerId/practitioner/:practitionerId',
                component: () => import("@/modules/profile/view/careers/ProfileCareerDetailView.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/profile/championship-rankings',
                component: () => import("@/modules/profile/view/ranking/championship/ProfileChampionshipRankingsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/horses',
                component: () => import("@/modules/profile/view/horses/ProfileHorsesView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/horses/:horseId',
                component: () => import("@/modules/profile/view/horses/ProfileHorseDetailView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/accounts',
                component: () => import("@/modules/profile/view/accounts/ProfileAccountsView.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profile/accounts/:accountId',
                component: () => import("@/modules/profile/view/accounts/ProfileAccountDetailView.vue"),
                meta: {
                    requiresAuth: true
                }
            },

            //championship
            {
                path: '/championship-ranking/:rankingRef',
                component: () => import("@/modules/championshipranking/view/ChampionshipRankingView.vue"),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/championship-ranking/:rankingRef/entry/:entryRef',
                component: () => import("@/modules/championshipranking/view/ChampionshipRankingEntryDetailView.vue"),
                meta: {
                    requiresAuth: false
                }
            },

            //settings
            {
                path: 'settings/config',
                component: () => import("@/modules/config/ConfigView.vue"),
            },
            /*{
                path: 'profile/receipt',
                component: () => import("@/modules/profile/RegistrationReceiptViewer.vue"),
                meta: {
                    requiresAuth: true
                },
            }*/
        ]
    },
    {
        path: '/payment-success',
        component: () => import("@/component/status/PaymentSucceeded.vue"),
    },
    {
        name: 'auth',
        path: '/auth',
        component: () => import("@/modules/auth/AuthView.vue"),
    },
    {
        name: 'login',
        path: '/auth/login',
        component: () => import("@/modules/auth/KeycloakFrameView.vue"),
    },
    {
        name: 'register',
        path: '/auth/register',
        component: () => import("@/modules/auth/KeycloakFrameView.vue"),
        props: {
            mode: "register",
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeResolve(async (to, from, next) => {
    if (to.meta?.requiresAuth == true) {
        const canAccess = await waitForIsAuthenticated();
        if (canAccess) {
            next()
        } else {
            console.error("Redirecting to login page because user is not authenticated")
            next(
                {
                    path: '/auth',
                    query: {
                        returnPath: to.path,
                        registerReturnPath: from.path
                    }
                }
            );
        }
    } else {
        next()
    }
})

export default router
