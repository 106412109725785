<template>
  <ion-page>
    <ion-toolbar mode="ios" color="transparant" class="mt-2">
      <ion-title>
        <span class="text_bold">Change config</span>
      </ion-title>
      <ion-buttons slot="start">
        <ion-back-button color="primary" defaultHref="/"></ion-back-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-content color="light">

      <ion-button
          expand="block"
          color="medium"
          class="ion-margin-start ion-margin-end ion-margin-bottom"
          @click="restoreProductionConfig">
        Restore production
      </ion-button>

      <ion-button
          expand="block"
          color="medium"
          class="ion-margin-start ion-margin-end ion-margin-bottom"
          @click="applyStagingConfig">
        Staging
      </ion-button>

      <ion-button
          expand="block"
          color="medium"
          class="ion-margin-start ion-margin-end ion-margin-bottom"
          @click="applyDevelopmentConfig">
        Development
      </ion-button>

    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonToolbar,
  IonTitle,
  IonPage,
  IonButtons,
  IonBackButton,
  IonContent,
  IonButton
} from '@ionic/vue';
import {environments, saveStaticConfig, removeStaticConfig} from "@/config";
import {useKeycloak} from "@/authentication/vue-keycloak";
import {useI18n} from "vue-i18n";

const keycloak = useKeycloak()
const {t} = useI18n()

function restoreProductionConfig() {
  removeStaticConfig()
      .then(reloadPage);
}

function applyStagingConfig() {
  saveStaticConfig(environments.staging)
      .then(reloadPage);
}

function applyDevelopmentConfig() {
  saveStaticConfig(environments.development)
      .then(reloadPage);
}

function reloadPage() {
  window.location.href = '/';
}
</script>
