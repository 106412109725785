import {getToken, useKeycloak} from "@/authentication/vue-keycloak";
import axios from "axios";
import {toastController} from "@ionic/vue";
import i18n from './i18n'

axios.interceptors.request.use(
    async config => {

        if (useKeycloak().isAuthenticated.value) {
            const token = await getToken()
            config.headers = {
                Authorization: `Bearer ${token}`
            }
        }

        config.headers = {
            ...config.headers,
            'x-eq-lang': i18n.global.locale.value.substring(0, 2)
        }

        return config
    },
    error => {
        Promise.reject(error)
    },
)

axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (error.response && 401 === error.response.status) {
        useKeycloak().keycloak.logout()
    } else {
        await errorToastr(error.response.status)
        return Promise.reject(error);
    }
});

const errorToastr = async function (status: number) {
    if (status != 0) {
        const toast = await toastController.create({
            message: i18n.global.t("error.general", {code: status}),
            color: 'danger',
            position: 'top',
            buttons: [
                {
                    text: 'ok',
                    role: 'cancel'
                }
            ]
        });

        await toast.present();
    }
}
