import {httpService} from "@/util/HttpService";

class AuthenticationService {
    async userCheck(email: string): Promise<LoggedInUserCheck> {
        return httpService.get(`/api/mobile/public/authentication/user-check?email=${email}`)
    }

    async userCheckConfirmation(email: string, body: LoggedInUserCheckConfirmation): Promise<LoggedInUserCheck> {
        return httpService.post(`/api/mobile/public/authentication/user-check-confirmation?email=${email}`, body)
    }
}

export interface LoggedInUserCheck {
    needsPersonConfirmation: boolean,
    persons: Array<PersonMetaData>,
    person?: PersonContext
}

export interface LoggedInUserCheckConfirmation {
    personRef: string,
    relations: Array<LoggedInUserChild>
}

export interface LoggedInUserChild {
    personRef: string,
    relationType: string
}

export interface PersonMetaData {
    ref: string,
    firstname: string,
    lastname: string,
    avatar?: string,
    birthYear?: string,
    organisations: Array<string>,
    practitionerCount: number
}

export interface PersonContext {
    ref: string
    firstname: string,
    lastname: string,
    avatar?: string,
    email?: string,

    username?: string
}

export const authenticationService = new AuthenticationService();