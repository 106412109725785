<template>
  <div>
    <img :class="size" :src="getFlagUrl()" alt="Language flag">
  </div>
</template>

<script lang="ts" setup>
import {defineProps} from "vue";

const props = defineProps<{
  flag?: string,
  size: string
}>()

function getFlagUrl() {
  if (props.flag !== '' && props.flag !== undefined && props.flag !== null) {
    return "/assets/flags/" + props.flag.toLowerCase() + '.svg'
  } else {
    return "/assets/flags/unknown.svg"
  }
}

</script>

<style lang="scss" scoped>
.sm {
  height: 20px;
}

.md {
  height: 45px;
}

.lg {
  height: 90px;
}
</style>