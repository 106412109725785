import {httpService} from "@/util/HttpService";

export interface StaticConfig {
    SERVER_URL: string,
    MEDIA_SERVER_URL: string
}


export interface DynamicConfig {
    WEBSOCKET_URL: string
    WEBSOCKET_VHOST: string
    WEBSOCKET_USERNAME: string
    WEBSOCKET_PASSWORD: string
    KEYCLOAK_URL: string
}

export interface Config extends StaticConfig, DynamicConfig {
}

export const environments: {
    production: StaticConfig,
    staging: StaticConfig,
    development: StaticConfig,
} = {
    production: {
        SERVER_URL: 'https://mobile.eqify.horse',
        MEDIA_SERVER_URL: 'https://media.eqify.horse'
    },
    staging: {
        SERVER_URL: 'https://mobile.staging.eqify.horse',
        MEDIA_SERVER_URL: 'https://media.staging.eqify.horse'
    },
    development: {
        SERVER_URL: 'http://localhost:8085',
        MEDIA_SERVER_URL: 'http://localhost:9001'
    },
}

export const config: Config = environments.production as Config;

export async function loadDynamicConfig(): Promise<Config> {
    return httpService.get<DynamicConfig>('/api/mobile/ui/config')
        .then((dynamicConfig) => Object.assign(config, dynamicConfig));
}
