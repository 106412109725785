<template>
  <card-item @click="selectNext">
    <template #content>
      <ion-label slot="start">
        {{ $t("settings.preferences.language") }}
      </ion-label>
      <ion-label slot="end">
        <flag-component :flag="currentLanguage.flag" size="sm"/>
      </ion-label>
    </template>
  </card-item>
</template>

<script lang="ts" setup>
import {IonLabel} from '@ionic/vue';
import {computed} from "vue";
import FlagComponent from "@/component/FlagComponent.vue";
import {useI18n} from "vue-i18n";
import {localeStoreService} from "@/storage/StorageService";
import {languages} from "@/i18n";
import CardItem from "@/component/CardItem.vue";

const i18n = useI18n()

const currentLanguageIndex = computed(() => {
  const currentIndex = languages.findIndex(it => it.lang === i18n.locale.value);
  if (currentIndex > -1) {
    return currentIndex;
  }

  const fallbackIndex = languages.findIndex(it => it.lang === i18n.fallbackLocale.value)
  if (fallbackIndex > -1) {
    return fallbackIndex;
  }

  return 0;
});
const currentLanguage = computed(() => languages[currentLanguageIndex.value]);

function selectNext() {
  const newLanguage = languages[(currentLanguageIndex.value + 1) % languages.length];
  i18n.locale.value = newLanguage.lang;
  localeStoreService.saveLocale(currentLanguage.value.lang);
}
</script>