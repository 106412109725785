import {httpService} from "@/util/HttpService";
import {
    OnlineShoppingCartCheckoutResponse,
    SaveRegistrationShoppingCartItem,
    ShoppingCart,
    ShoppingCartItem
} from "@/types/Cart";

class CartService {
    getCart(): Promise<ShoppingCart> {
        return httpService.get(`/api/mobile/public/cart`)
    }

    addItem(body: SaveRegistrationShoppingCartItem): Promise<ShoppingCartItem> {
        return httpService.put(`/api/mobile/public/cart`, body)
    }

    deleteItem(itemId: string) {
        return httpService.delete(`/api/mobile/public/cart/item/${itemId}`)
    }

    checkout(continueWithWarnings: boolean, accountId?: string): Promise<OnlineShoppingCartCheckoutResponse> {
        return httpService.post(`/api/mobile/public/cart/checkout?continueWithWarnings=${continueWithWarnings}&accountId=${accountId}`, {})
    }
}

export const cartService = new CartService();