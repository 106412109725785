import {Preferences} from "@capacitor/preferences";
import {config, StaticConfig} from "./config";

const KEY = 'config'
const RESET_QUERY_PARAM = 'reset-config'

export async function loadStaticConfig(): Promise<StaticConfig> {
    const preference = await Preferences.get({ key: KEY });
    if (preference.value) {
        if (window.location.search.indexOf(RESET_QUERY_PARAM) > 0) {
            await removeStaticConfig();
            window.location.reload();
        }

        Object.assign(config, JSON.parse(preference.value))
    }
    return config;
}

export function saveStaticConfig(config: StaticConfig): Promise<void> {
    return Preferences.set({ key: KEY, value: JSON.stringify(config) });
}

export function removeStaticConfig(): Promise<void> {
    return Preferences.remove({ key: KEY });
}