<template>
  <ion-content>
    <card-item class="mt-3" lines="none">
      <template #content>
        <ion-avatar slot="start">
          <img alt="p-a" v-if="data.avatar" :src="data.avatar">
          <img alt="p-a" v-else src="/assets/icon/favicon.png">
        </ion-avatar>
        <ion-label>
          <h6 class="name_with_sub_item">
            <span>{{ data.firstname }} {{ data.lastname }}</span>
          </h6>
        </ion-label>
        <small slot="end" class="text_italic"> {{ data.birthYear }}</small>
      </template>
    </card-item>
    <ion-item lines="full">
      <ion-label slot="start">
        <small>{{ $t('syncing.modal.profile.organisations') }}</small>
      </ion-label>
      <template v-if="data.organisations?.length > 0">
        <div slot="end" v-for="(club, idx) in data.organisations" :key="idx">
          <small>{{ club }}</small>
        </div>
      </template>
      <template v-else>
        <small slot="end">{{ $t('syncing.modal.profile.none') }}</small>
      </template>
    </ion-item>
    <ion-item lines="full">
      <ion-label slot="start">
        <small>{{ $t('syncing.modal.profile.horse_count') }}</small>
      </ion-label>
      <small slot="end">{{ data.practitionerCount }}</small>
    </ion-item>
    <ion-item lines="none" color="dark">
      <ion-label>
        <small>{{ $t('syncing.modal.profile.select_type') }}</small>
      </ion-label>
    </ion-item>
    <ion-item @click="selectType(type)" button v-for="(type, idx) in allowedTypes()" :key="idx">
      <span>{{ $t(`syncing.modal.profile.relation_types.${type}`) }}</span>
    </ion-item>
    <ion-item button @click="selectNone()">
      <ion-text color="danger">{{ $t('syncing.modal.profile.unknown') }}</ion-text>
    </ion-item>
  </ion-content>
</template>

<script lang="ts" setup>
import {
  IonContent,
  IonItem,
  IonAvatar,
  IonText,
  IonLabel, modalController
} from '@ionic/vue';
import {defineProps} from "vue";
import {PersonMetaData} from "@/authentication/AuthenticationService";
import CardItem from "@/component/CardItem.vue";

enum RelationType {
  ME = "ME",
  MOTHER = "MOTHER",
  FATHER = 'FATHER',
  OTHER_FAMILY = "OTHER_FAMILY",
  ASSOCIATE = "ASSOCIATE"
}

const props = defineProps<{
  data: PersonMetaData,
  showMeOption: boolean,
  showOnlyMeOption: boolean
}>()

function allowedTypes(): Array<string> {
  if (props.showOnlyMeOption) return [RelationType.ME]
  else if (!props.showMeOption) return Object.keys(RelationType)
  else return Object.keys(RelationType).filter(it => it != 'ME')

}

function selectType(type: string) {
  modalController.dismiss({
    ref: props.data.ref,
    role: type
  }, 'select')
}

function selectNone() {
  modalController.dismiss({
    ref: props.data.ref,
    role: undefined
  }, 'select-none')
}

</script>

<style scoped>

</style>