import {httpService} from "@/util/HttpService";
import {Channel} from "@/types/Channel";
import {IndexCompetition} from "@/types/Competition";

class ChannelService {
    getAll(): Promise<Array<Channel>> {
        return httpService.get("/api/mobile/public/channels")
    }

    getFeatured(): Promise<Array<Channel>> {
        return httpService.get("/api/mobile/public/channels/featured")
    }

    get(id: string): Promise<Channel> {
        return httpService.get(`/api/mobile/public/channels/${id}`)
    }

    getLiveCompetitions(id: string): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/channels/${id}/live`)
    }

    getThisWeekCompetitions(id: string): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/channels/${id}/events/this-week`)
    }

    getLastWeekCompetitions(id: string): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/channels/${id}/events/last-week`)
    }

    getNextWeekCompetitions(id: string): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/channels/${id}/events/next-week`)
    }

    getLaterCompetitions(id: string): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/channels/${id}/events/later`)
    }

    getOlderCompetitions(id: string): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/channels/${id}/events/older`)
    }
}

export const channelService = new ChannelService()