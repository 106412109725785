import {App, inject, provide} from "vue";
import webstomp, {Client, Message, Subscription} from 'webstomp-client';

/*export default {
    install: (app: App, options?: WebstompPluginOptions) => {
        const webstomp = new DefaultWebStomp(options!)
        app.config.globalProperties.$webstomp =  webstomp
        app.provide('webstomp', webstomp)
    }
}*/

export interface Webstomp {
    subscribe(destination: string, callback: (message: Message) => void): number
    unsubscribe(requestId: number): void
}

class DefaultWebStomp implements Webstomp {
    subscriptionRequests: Array<SubscriptionRequest> = []
    subscriptions: Array<StompSubscription> = []
    client?: Client;
    requestId = 0
    retryLoop: any = undefined

    constructor(readonly options: WebstompPluginOptions) {
        this._connect()
    }

    subscribe(destination: string, callback: (message: Message) => void): number {
        const request = new SubscriptionRequest(this.requestId++, destination, callback)
        this.subscriptionRequests.push(request)
        this._subscribe(request)
        return request.requestId
    }

    unsubscribe(requestId: number) {
        const requestIndex = this.subscriptionRequests.findIndex(it => it.requestId == requestId)
        if (requestIndex >= 0) {
            this._unsubscribe(this.subscriptionRequests[requestIndex]!)
        }
    }

    private _subscribe(request: SubscriptionRequest) {
        if (this.isConnected) {
            const s = this.client!.subscribe(request.destination, request.callback)
            this.subscriptions.push(new StompSubscription(request.requestId, s))
        }
    }

    private _unsubscribe(subscription: SubscriptionRequest) {
        const subscriptionIndex = this.subscriptions.findIndex(it => it.requestId == subscription.requestId)
        if (subscriptionIndex >= 0) {
            if (this.isConnected) {
                this.subscriptions[subscriptionIndex]?.subscription?.unsubscribe()
            }
            this.subscriptions.splice(subscriptionIndex, 1)
        }
        this.subscriptionRequests.splice(this.subscriptionRequests.indexOf(subscription), 1)
    }

    private onConnected() {
        this.subscriptions = []
        this.subscriptionRequests.forEach(r => this._subscribe(r))
    }

    private get isConnected(): boolean {
        return this.client !== undefined && this.client.ws.readyState == WebSocket.OPEN
    }

    private _connect() {
        const ws = new WebSocket(this.options!.url);
        const client = webstomp.over(ws, {debug: false, heartbeat: {incoming: 2000, outgoing: 2000}});
        client.connect(this.options!.login, this.options!.passcode,
            () => {
                if (this.retryLoop) {
                    clearTimeout(this.retryLoop)
                }
                this.client = client
                this.onConnected()
            },
            (e) => {
                this.retryLoop = setTimeout(this._connect.bind(this), 2000)
            },
            this.options!.vhost
        )
    }
}

class SubscriptionRequest {
    constructor(readonly requestId: number, readonly destination: string, readonly callback: (message: Message) => void) {
    }
}

class StompSubscription {
    constructor(readonly requestId: number, readonly subscription: Subscription) {
    }
}

interface WebstompPluginOptions {
    login: string,
    passcode: string,
    url: string,
    vhost: string
}

export function provideWebstompPlugin(app: App, options: WebstompPluginOptions) {
    const webstompPlugin: Webstomp = new DefaultWebStomp(options)
    app.provide('webstomp', webstompPlugin)
}

export function useWebstomp(): Webstomp {
    const webstomp = inject('webstomp')
    if (!webstomp) throw new Error("No Webstomp provided!")
    return webstomp as Webstomp
}
