import {defineStore} from "pinia";
import {Channel} from "@/types/Channel";
import {channelService} from "@/modules/home/channels/service/ChannelService";

export const useChannelsStore = defineStore('channelsStore', {
    state: () => {
        return {
            loading: false,
            channels: [] as Array<Channel>
        }
    },
    actions: {
        async load() {
            this.loading = true
            this.channels = await channelService.getAll()
            this.loading = false
        }
    }
})