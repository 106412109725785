import {useKeycloak} from "@/authentication/vue-keycloak";
import {WatchStopHandle} from "@vue/runtime-core";
import {watch} from "vue";

const {isPending, isAuthenticated} = useKeycloak();

export default function waitForIsAuthenticated(): Promise<boolean> {
    return waitForPending().then(() => isAuthenticated.value);
}

function waitForPending() {
    return new Promise((resolve) => {
        if (!isPending.value) {
            resolve(isPending.value);
            return;
        }

        let stopWatching: WatchStopHandle = noop;
        stopWatching = watch(isPending, (p) => {
            if (!p) {
                stopWatching();
                resolve(p);
            }
        });
    });
}

// eslint-disable-next-line
function noop(): void {}
