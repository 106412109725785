<template>
  <ion-header class="ion-no-border">
    <ion-toolbar
        mode="ios"
        :color="background()"
        :class="{'ion-no-margin': true, 'position-absolute': hasAbsoluteHeader() }">
      <div class="logo-title">
        <img class="eqifyBorder" src="/eqify-logo-dark.svg">
      </div>
      <ion-buttons slot="start">
        <ion-back-button
            :text="$t('common.back')"
            mode="ios" v-if="enableBackButton" defaultHref="/"></ion-back-button>
              <ion-menu-button menu="settings-menu" mode="ios" v-else></ion-menu-button>
      </ion-buttons>
      <div slot="end">
        <slot name="end"/>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts" setup>
import {
  IonHeader,
  IonButtons,
  IonToolbar,
  IonBackButton,
  IonMenuButton,
} from '@ionic/vue';
import {defineProps} from "vue";

const props = defineProps({
  backgroundColor: String,
  enableBackButton: Boolean,
  absoluteHeader: Boolean
})

function background() {
  return props.backgroundColor || 'transparant'
}

function hasAbsoluteHeader(): boolean {
  return props.absoluteHeader == true
}

</script>

<style lang="scss" scoped>
.logo-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  img {
    width: 75px;
  }
}

.position-absolute {
  position: absolute;
}
</style>