<template>
  <ion-header class="ion-no-border" mode="ios">
    <ion-toolbar mode="ios" color="transparant">
      <ion-title>{{ $t("settings.title") }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>

    <ion-item lines="none">
      <small>{{ $t("settings.preferences.label") }}</small>
    </ion-item>

    <locale-select/>

    <card-item v-if="!keycloak.isAuthenticated.value" class="mt-1" button @click="login">
      <template #content>
        {{ $t("auth.login") }}
      </template>
    </card-item>

  </ion-content>
</template>

<script setup>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  menuController, useIonRouter,
} from "@ionic/vue";
import CardItem from "@/component/CardItem.vue";
import LocaleSelect from "@/component/LocaleSelect.vue";
import {useKeycloak} from '@/authentication/vue-keycloak';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';

const keycloak = useKeycloak()
const {t} = useI18n()

const router = useIonRouter();
const route = useRoute();

async function login() {
  await router.push({
    path: "/auth/login",
    query: {
      returnPath: route.path,
    },
  });
  await menuController.toggle();
}

</script>
