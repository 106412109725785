import axios from "axios";
import config from "@/config";

export interface HttpService {
    get<T>(path: string): Promise<T>;

    post<B, T>(path: string, body: B): Promise<T>;

    put<B, T>(path: string, body: B): Promise<T>;

    delete<T>(path: string): Promise<T>;
}

class DefaultHttpService implements HttpService {
    get<T>(path: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            axios.get(`${config.SERVER_URL}${path}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    post<B, T>(path: string, body: B): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            axios.post(`${config.SERVER_URL}${path}`, body)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    put<B, T>(path: string, body: B): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            axios.put(`${config.SERVER_URL}${path}`, body)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    delete<T>(path: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            axios.delete(`${config.SERVER_URL}${path}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
}

export const httpService = new DefaultHttpService();