<template>
  <ion-page>
    <ion-tabs mode="md">

      <ion-router-outlet></ion-router-outlet>

      <offline-item></offline-item>

      <ion-tab-bar class="eqify_tabbar" slot="bottom" color="medium">

        <ion-tab-button class="tabbar_item no-ripple" tab="competition" href="/tabs/competition">
          <ion-label>{{ $t("tabs.start") }}</ion-label>
          <ion-icon :icon="homeOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button class="tabbar_item no-ripple" tab="search" href="/tabs/search">
          <ion-label>{{ $t("tabs.search") }}</ion-label>
          <ion-icon :icon="searchOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button class="tabbar_item no-ripple" tab="championship" href="/tabs/championship">
          <ion-icon color="warning" :icon="trophyOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button class="tabbar_item no-ripple" tab="cart" href="/tabs/cart">
          <ion-label>{{ $t("tabs.cart") }}</ion-label>
          <ion-icon :icon="cartOutline"></ion-icon>
          <ion-badge
              class="animate__animated animate__heartBeat animate__infinite"
              v-if="cartItemCount !== 0 && isLoggedIn"
              color="danger">
            {{ cartItemCount }}
          </ion-badge>
        </ion-tab-button>

        <ion-tab-button class="tabbar_item no-ripple" tab="profile" href="/tabs/profile">
          <ion-label v-if="firstname">{{ firstname }}</ion-label>
          <ion-label v-else>{{ $t("tabs.profile") }}</ion-label>
          <ion-icon :icon="personOutline"></ion-icon>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonBadge,
  IonText
} from '@ionic/vue';
import {homeOutline, personOutline, cartOutline, searchOutline, trophyOutline} from 'ionicons/icons';
import {computed, onMounted, ref, Ref, watch} from "vue";
import {useKeycloak} from '@/authentication/vue-keycloak';
import OfflineItem from "@/component/OfflineItem.vue";
import {useCartStore} from "@/modules/payment/store/CartStore";
import 'animate.css';

let cartItemCount = ref(0)

const cartStore = useCartStore()
const {decodedToken}: { decodedToken: Ref } = useKeycloak() as any;
const keycloak = useKeycloak()

const isLoggedIn = computed((): boolean => {
  return keycloak.isAuthenticated.value
})

watch(isLoggedIn, async () => {
  await cartStore.get()
  cartItemCount.value = cartStore.items.length
  cartStore.$subscribe(() => {
    cartItemCount.value = cartStore.items.length
  }, {detached: true})
})

const firstname = computed(() => {
  if (!decodedToken || !decodedToken.value) {
    return undefined;
  }

  return decodedToken.value.given_name;
})
</script>

<style lang="scss">

.ion-tab-button {
  touch-action: manipulation !important;
}

</style>