import {defineStore} from "pinia";
import {IndexCompetition} from "@/types/Competition";
import {FilterContext, myCalendarService} from "@/modules/home/mycalendar/service/MyCalendarService";

export const useMyCalendarStore = defineStore('myCalendarStore', {
    state: () => {
        return {
            loading: false,

            thisWeek: [] as Array<IndexCompetition>,
            lastWeek: [] as Array<IndexCompetition>,
            nextWeek: [] as Array<IndexCompetition>,
            older: [] as Array<IndexCompetition>,
            later: [] as Array<IndexCompetition>
        }
    },
    getters: {
        hasCompetitions(): boolean {
            return (
                this.thisWeek.length > 0 ||
                this.lastWeek.length > 0 ||
                this.nextWeek.length > 0 ||
                this.older.length > 0 ||
                this.later.length > 0
            )
        }
    },
    actions: {
        reload(filterContext: FilterContext) {
            this.startLoading()
            Promise.all([
                this.loadThisWeek(filterContext)
            ])
                .then(() => {
                    this.stopLoading()
                    this.loadOther(filterContext)
                })
        },
        async loadAll(filterContext: FilterContext) {
            await this.loadThisWeek(filterContext)
            await this.loadOther(filterContext)
        },
        async loadThisWeek(filterContext: FilterContext) {
            this.thisWeek = await myCalendarService.loadThisWeekCompetitions(filterContext)
        },
        async loadOther(filterContext: FilterContext) {
            const response = await myCalendarService.loadOther(filterContext)
            this.lastWeek = response.lastWeek
            this.nextWeek = response.nextWeek
            this.later = response.later
            this.older = response.older
        },
        startLoading() {
            this.loading = true
        },
        stopLoading() {
            this.loading = false
        }
    }
})