import {Config} from "@/config";
import {KeycloakConfig, KeycloakInitOptions} from "@/authentication/keycloak-js/keycloak";

export interface VueKeycloakOptions {
    initOptions: KeycloakInitOptions
    config: KeycloakConfig
}

export function keycloakOptions(config: Config): VueKeycloakOptions {
    return {
        initOptions: {
            onLoad: 'check-sso',
            responseMode: 'query',
            adapter: 'iframe',
            redirectUri: createKeycloakRedirectUri(config, `${window.location.origin}/silent-check-sso.html`),
            silentCheckSsoRedirectUri: createKeycloakRedirectUri(config, `${window.location.origin}/silent-check-sso.html`),
            pkceMethod: 'S256',
        },
        config: {
            url: config.KEYCLOAK_URL,
            realm: 'eqify',
            clientId: 'eqify-mobile',
        }
    };
}

export function createKeycloakRedirectUri(config: Config, redirectUri: string): string {
    const redirectTo = encodeURIComponent(redirectUri);
    return `${config.SERVER_URL}/api/mobile/public/authentication/js-redirect.html?redirect-to=${redirectTo}`;
}
