import {ref, computed} from "vue";
import {Network, ConnectionStatus} from "@capacitor/network";

export const online = computed(() => onlineRef.value);

const onlineRef = ref(false);

(async function initOnline(): Promise<void> {
    updateOnline(await Network.getStatus());
    Network.addListener('networkStatusChange', updateOnline);
})();


function updateOnline(status: ConnectionStatus): void {
    onlineRef.value = !!status && status.connected;
}
