import {httpService} from "@/util/HttpService";
import {Registration} from "@/types/Registration";
import {RiderMasterlistCompetitionEntry} from "@/service/RiderMasterlistService";
import {Order, OrderItem} from "@/types/Order";
import {Bill, BillItem} from "@/types/Bill";
import {SearchDetailCompetition} from "@/types/Global";
import {RiderMasterlistItem} from "@/types/Masterlist";
import {PersonCareerDetail, PersonCareers} from "@/types/Career";
import {ProfileChampionshipRankingEntries, ProfileChampionshipRankings} from "@/types/ProfileChampionshipRanking";
import {ProfileHorses} from "@/types/ProfileHorse";
import {PersonAccount, PersonAccounts, SavePersonAccountRequest} from "@/types/PersonAccount";
import {OnlinePaymentInstruction} from "@/types/Cart";

class ProfileService {
    getUserProfile(): Promise<UserProfile> {
        return httpService.get("/api/mobile/private/profile")
    }

    getRegistrationCompetitions(): Promise<RegistrationCompetitions> {
        return httpService.get("/api/mobile/private/profile/registrations")
    }

    getCompetitionRegistrations(competitionId: string): Promise<CompetitionRegistrations> {
        return httpService.get(`/api/mobile/private/profile/registrations/${competitionId}`)
    }

    getMasterlistCompetitions(): Promise<Array<SearchDetailCompetition>> {
        return httpService.get(`/api/mobile/private/profile/masterlist-competitions`)
    }

    getPersonMasterlistEntriesForCompetition(competitionId: string): Promise<Array<RiderMasterlistItem>> {
        return httpService.get(`/api/mobile/private/profile/masterlist-entries/${competitionId}`)
    }

    getOrders(year: string): Promise<PersonOrders> {
        return httpService.get(`/api/mobile/private/profile/orders?year=${year}`)
    }

    getOrder(orderRef: string): Promise<OrderDetail> {
        return httpService.get(`/api/mobile/private/profile/order/${orderRef}`)
    }

    redoOrderPayment(orderRef: string): Promise<OnlinePaymentInstruction> {
        return httpService.post(`/api/mobile/private/profile/order/${orderRef}/redo-payment`, {})
    }

    getBills(year: string): Promise<PersonBills> {
        return httpService.get(`/api/mobile/private/profile/bills?year=${year}`)
    }

    getBill(billRef: string): Promise<BillDetail> {
        return httpService.get(`/api/mobile/private/profile/bill/${billRef}`)
    }

    getTodayMasterlist(): Promise<PersonMasterlist> {
        return httpService.get(`/api/mobile/private/profile/masterlists/today?timestamp=${this.getNowUnix()}`)
    }

    getTodayMasterlistWidget(): Promise<PersonMasterlist> {
        return httpService.get(`/api/mobile/private/profile/masterlists/widget?timestamp=${this.getNowUnix()}`)
    }

    getCareers(all: boolean): Promise<PersonCareers> {
        return httpService.get(`/api/mobile/private/profile/careers?all=${all}`)
    }

    getCareerDetails(
        careerRef: string,
        practitionerRef: string
    ): Promise<PersonCareerDetail> {
        return httpService.get(`/api/mobile/private/profile/careers/${careerRef}/practitioner/${practitionerRef}`)
    }

    getChampionshipRankings(year: string): Promise<ProfileChampionshipRankings> {
        return httpService.get(`/api/mobile/private/profile/championship-rankings?year=${year}`)
    }

    getHorses(): Promise<ProfileHorses> {
        return httpService.get(`/api/mobile/private/profile/horses`)
    }

    getAccounts(): Promise<ProfileAccounts> {
        return httpService.get(`/api/mobile/private/profile/accounts`)
    }

    createAccount(request: SavePersonAccountRequest): Promise<PersonAccount> {
        return httpService.post(`/api/mobile/private/profile/accounts`, request)
    }

    updateAccount(accountId: string, request: SavePersonAccountRequest): Promise<PersonAccount> {
        return httpService.post(`/api/mobile/private/profile/accounts/${accountId}`, request)
    }

    deleteAccount(accountId: string): Promise<void> {
        return httpService.delete(`/api/mobile/private/profile/accounts/${accountId}`)
    }

    private getNowUnix(): string {
        return Math.round(+ new Date() / 1000).toString();
    }
}

export interface ProfileAccounts {
    accounts: Array<PersonAccounts>
}

export interface OrderDetail {
    order: Order,
    items: Array<OrderItem>
}

export interface RegistrationCompetitions {
    competitions: Array<SearchDetailCompetition>
}

export interface CompetitionRegistrations {
    registrations: Array<Registration>
}

export interface PersonOrders {
    orders: Array<Order>
}

export interface BillDetail {
    bill: Bill,
    items: Array<BillItem>
}

export interface PersonBills {
    bills: Array<Bill>
}

export interface PersonMasterlist {
    entries: Array<RiderMasterlistCompetitionEntry>
}

export const initUserProfile = () => {
    const data: UserProfile = {
        name: "",
        firstname: "",
        lastname: "",
        email: ""
    }
    return data
}

export interface UserProfile {
    name: string
    firstname: string,
    lastname: string,
    email: string
}

export const profileService = new ProfileService();