import router from './router';
import {App} from "@capacitor/app";
import extractUrlPath from "@/router/extract-url-path";

export async function initializeUrlOpenListener(): Promise<void> {
    await App.addListener('appUrlOpen', async event => {
        const path = extractUrlPath(event.url);
        if (path) {
            await router.push(path);
        }
    });
}
