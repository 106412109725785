<template>
  <ion-item lines="none" :class="`pl-2 pr-2 ${classes()}`" color="light" :button="asButton" mode="ios">
    <slot name="content"/>
  </ion-item>
</template>

<script lang="ts" setup>
import {
  IonItem
} from '@ionic/vue';
import {computed, defineProps} from "vue";

const props = defineProps<{
  button?: boolean,
  border?: boolean
}>()

const asButton = computed(() => {
  return props.button == undefined ? true : props.button
})

function classes() {
  if (props.border) {
    return `border-color`
  }
}

</script>

<style lang="scss" scoped>
ion-item::part(native) {
  border-radius: 5px !important;
  overflow: hidden;
  cursor: pointer;

  border-left: 5px solid;
  border-left-color: #7a7a7a;
}

</style>