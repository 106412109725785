import {defineStore} from "pinia";
import {IndexCompetition} from "@/types/Competition";
import {competitionService} from "@/modules/competition/service/CompetitionService";
import {RiderMasterlistCompetitionEntry} from "@/service/RiderMasterlistService";
import {profileService} from "@/modules/profile/service/ProfileService";
import {channelService} from "@/modules/home/channels/service/ChannelService";
import {Channel} from "@/types/Channel";

export const useCompetitionStore = defineStore('competitionStore', {
    state: () => {
        return {
            loading: false,

            live: [] as Array<IndexCompetition>,
            thisWeek: [] as Array<IndexCompetition>,
            lastWeek: [] as Array<IndexCompetition>,
            nextWeek: [] as Array<IndexCompetition>,
            older: [] as Array<IndexCompetition>,
            later: [] as Array<IndexCompetition>,

            channels: [] as Array<Channel>,

            personMasterlistEntries: [] as Array<RiderMasterlistCompetitionEntry>
        }
    },
    getters: {
        hasLiveCompetitions(): boolean {
            return this.live.length > 0
        },
        hasCompetitions(): boolean {
            return (
                this.live.length > 0 ||
                this.thisWeek.length > 0 ||
                this.lastWeek.length > 0 ||
                this.nextWeek.length > 0 ||
                this.older.length > 0 ||
                this.later.length > 0
            )
        }
    },
    actions: {
        reload() {
            this.startLoading()
            Promise.all([
                this.loadLive(),
                this.loadChannels(),
                this.loadThisWeek()
            ])
                .then(() => {
                    this.stopLoading()
                    this.loadOther()
                })
        },
        async loadAll() {
            await this.loadLive()
            await this.loadThisWeek()
            await this.loadOther()
            await this.loadChannels()
        },
        async loadLive() {
            this.live = await competitionService.loadLiveCompetitions()
        },
        async loadThisWeek() {
            this.thisWeek = await competitionService.loadThisWeekCompetitions()
        },
        async loadOther() {
            const response = await competitionService.loadOther()
            this.lastWeek = response.lastWeek
            this.nextWeek = response.nextWeek
            this.later = response.later
            this.older = response.older
        },
        async loadPersonMasterlist() {
            const personMasterlist = await profileService.getTodayMasterlistWidget()
            this.personMasterlistEntries = personMasterlist.entries
        },
        async loadChannels() {
            this.channels = await channelService.getFeatured()
        },
        startLoading() {
            this.loading = true
        },
        stopLoading() {
            this.loading = false
        }
    }
})